import React, { Component } from "react";
import { translate, getActiveLanguage } from '@haldor/ui';
import { connect } from "react-redux";
import { Field, Form } from "react-final-form";
import { withRouter } from 'react-router-dom';
import Moment from 'moment';
import swal from 'sweetalert2';

import { addError } from 'actions';
import {
	createAssignment,
	getTaskDetails,
	setActiveAssignment,
	updateAssignment,
} from 'actions/assignments';

import {
	getPlanDetails,
	getPlansBySectionId,
	setActivePlan,
	clearSectionPlansFromStore,
} from 'actions/plans';

import { createAssessmentBlock, getBlocksByReference, updateAssessmentBlock, updateBlocksOnReference, deleteBlock } from 'actions/blocks';
import { clearSelectedSections, getSection, toggleSelectedSection, setActiveSection } from 'actions/sections';
import { getScheduleForUser, getScheduleItems, clearSearchedSchedules } from 'actions/schedule';

import { getRootUrl } from 'helpers/url';

import DatePickerFromTo from '../Partials/DatePickerFromTo';
import GroupSelector from '../Partials/GroupSelector';
import InvalidGroupNotice from '../Partials/InvalidGroupNotice';
import TeacherAdjustments from 'containers/AdditionalAdjustments/Display/TeacherAdjustments';
import AssignmentTaskSelect from 'components/List/AssignmentTaskSelect';
import Modal from 'containers/Modals/Modal';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import sv from 'date-fns/locale/sv';
import no from 'date-fns/locale/nb';
import en from 'date-fns/locale/en-US';
import da from "date-fns/locale/da";
import uk from "date-fns/locale/uk";

import Select from "components/Inputs/select";
import Upload from "components/Inputs/upload";
import AttachedDocuments from "components/Presentation/Documents/AttachedDocuments";
import BlockAssessments from "components/BlockAssessments";

import { Expandable, Spinner } from 'UI';
import { Editor, TimePicker } from 'UI/Inputs';
import { Button, Checkbox } from '@haldor/ui';
import CustomSelector from '../Partials/CustomSelector';

class AssignmentForm extends Component {
	constructor(props) {
		super(props);

		registerLocale('en-US', en);
		registerLocale('sv-SE', sv);
		registerLocale('nb-NO', no);
		registerLocale('da-DK', da);
		registerLocale('uk', uk);
		setDefaultLocale('en-US');

		let attachedFiles = [];

		let dateStart = Moment().second(0);
		let dueDate = null;
		if (props.date != null) {
			dateStart = props.date;
		}

		if (props.initialValues?.startDate) {
			dateStart = Moment.utc(props.initialValues?.startDate).local();
		}

		if (props.initialValues?.dueDate) {
			dueDate = props.initialValues?.dueDate;
		}

		let published = props.initialValues?.published !== '0001-01-01T00:00:00' ?
			Moment.utc(props?.initialValues?.published).local()
			: Moment().local();

		this.inititalPublished = published;

		this.state = {
			dateStart: props.hasDate ? Moment(props.hasDate) : dateStart,
			dateEnd: dueDate,
			sectionId: '0',
			tasks: null,
			attachedFiles: attachedFiles,
			newDocuments: [],
			loading: false,
			updated: false,
			createEvent: false,
			published: published,
			tempPublishDate: published,
			allowFetch: true,
			lesson: [],
			status: props.initialValues?.status ?? 'ASSIGNMENT_OPEN',
			isFetchingLesson: false,
			enableAutoAssign: null,
			selectedLesson: [],
			toggleLesson: {},
			planid: props.planid != null ? props.planid : 0,
			fileTypes: [
				{ value: 1, text: this.props.translate('instructions') },
				{ value: 2, text: this.props.translate('template-document') },
			],
		};

		this.formRef = null;
	}

	static defaultProps = {
		disablePlanSelector: false,
	};

	/* Redux form functions */
	required = (value) => {
		if (typeof value != 'undefined' && value != '' && value != null) {
			if (value.length > 99) {
				// Field failed validation, return error for this field (String)
				return this.props.translate('field-max-100-chars');
			}

			// Field passes validation, return a undefined error for this field
			// Rule: Not undefined and not an empty string and not over 200 characters
			return undefined;
		}

		// Field failed validation, return error for this field (String)
		return this.props.translate('field-needs-input');
	};

	requireAssignmentTasks = (values) => {
		console.log(values);

		let section = this.props.section != null ? this.props.section : null;

		if (section != null) {
			if (values != null && values.length === section.students.length && values.every((task) => (task.id == -1 || task.id > 0) && !task.markedForDeletion)) {
				this.setState({ enableAutoAssign: true });
			} else {
				this.setState({ enableAutoAssign: false });
			}
		}

		if (values == null || values.length == 0) {
			return this.props.translate('field-needs-input');
		}

		if (!this.props.editView) {
			let countTaskAssignees = 0;
			values.forEach(element => {
				if (element.students?.length > 0) {
					countTaskAssignees += element.students?.length;
				}
			});

			if (countTaskAssignees > 0) {
				return undefined;
			}

			return this.props.translate('field-needs-input');
		}

		//handle validation for groups if enabled
		if (this.props.initialValues?.groupEnabled) {
			let countTaskAssignees = 0;
			values.forEach(element => {
				if (element.students?.length > 0) {
					countTaskAssignees += element.students?.length;
				}
			});

			if (countTaskAssignees > 0) {
				return undefined;
			}

			return this.props.translate('field-needs-input');
		}

		let studentsArr = [];
		//check how many of the task has markedForDeletion
		let getDeleted = values?.filter(x => x?.markedForDeletion);

		//handle groupassignments
		values.forEach(element => {
			studentsArr = [...studentsArr, ...element.students]
		});

		if (getDeleted.length === studentsArr.length) {
			return this.props.translate('field-needs-input');
		}

		return undefined;
	};

	componentDidUpdate(prevProps, prevState) {
		/**
		 * When componend mount it will be null, first form load then the enableAutoAssign will be set to true or false, if it false, we need to set autoAssignStudents to false
		 * if it was true and now is false, we need to set autoAssignStudents to false
		 */
		if ((prevState.enableAutoAssign == null || prevState.enableAutoAssign === true) && this.state.enableAutoAssign === false) {
			this.formRef.mutators.setAutoAssignStudents(false);
		}
	}

	componentWillUnmount() {
		if (window.location.pathname.indexOf('/plan/') == -1) {
			this.props.setActivePlan();
		}

		if (this.props.cloneView && this.props.clearOnUnmount) {
			this.props.setActiveAssignment();
		}

		/**
		 * only clear selected sections if the plan selector is not enabled, if it is enabled probably user is creating assignment from plan
		 */
		if (!this.props.disablePlanSelector) {
			this.props.setActiveSection(null);
		}

		this.props.clearSelectedSections();
		this.props.clearSearchedSchedules();
	}

	UNSAFE_componentWillMount = () => {
		if (this.props.cloneView) {
			this.setState({ loading: true });
		}

		this.props.clearSectionPlansFromStore();
	};

	getToggleLesson = (data) => {
		let obj = {};
		data.forEach(element => {
			obj = { ...obj, [element.id]: true }
		});
		return obj;
	}

	componentDidMount = () => {
		if (this.props.groupid != null) {
			const sectionId = this.props.groupid;
			var find = this.props.sections.find((t) => t.id == sectionId);

			if (find == null) {
				this.setState({ sectionId: 0 });
			} else {
				this.setState({ sectionId, loading: true }, () => {
					let promises = [];

					promises.push(this.props.getPlansBySectionId(sectionId));
					promises.push(this.props.getSection(sectionId));

					if (this.props.cloneView) {
						promises.push(this.props.getTaskDetails(this.props.task.id));
					}

					Promise.all(promises).then(() => {
						this.setState({ loading: false });

						this.props.toggleSelectedSection(this.props.section);
					});
				});
			}
		}

		if (this.props.planid != null) {
			this.props.getPlanDetails(this.props.planid);
		}

		if (this.props.cloneView) {
			this.setState({ loading: true });
			this.props.getTaskDetails(this.props.task.id).then(() => {
				this.setState({ loading: false });
			});
		}

		if (this.props.initialValues?.relationships?.length > 0) {
			let promises = [];
			this.props.initialValues.relationships.forEach(element => {
				promises.push(this.props.getScheduleItems(element.calendarEventId))
			});

			Promise.all(promises).then(() => {
				this.setState({
					selectedLesson: this.props.connectedLessons,
					lesson: this.props.connectedLessons,
					toggleLesson: this.getToggleLesson(this.props.connectedLessons),
				});
			});
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.cloneView && nextProps.initialValues != null && this.state.updated == false) {
			let attachedFiles = this.state.attachedFiles;

			if (nextProps.initialValues.assignmentDocuments != null) {
				var files = nextProps.initialValues.assignmentDocuments.split(';');

				files.forEach((file) => {
					if (file != '') {
						var fileObj = {
							key: file.substring(file.lastIndexOf('/') + 1),
							value: file,
							fileType: 1,
						};

						attachedFiles.push(fileObj);
					}
				});
			}

			if (nextProps.initialValues.documentTemplates != null) {
				var files = nextProps.initialValues.documentTemplates.split(';');
				files.forEach((file) => {
					if (file != '') {
						var fileObj = {
							key: file.substring(file.lastIndexOf('/') + 1),
							value: file,
							fileType: 2,
						};

						attachedFiles.push(fileObj);
					}
				});
			}

			if (attachedFiles.length > 0) {
				this.setState({ attachedFiles, updated: true });
			}
		}
	}

	fixDescription = (html) => {
		html = html.replace(/&lt;/g, '<');
		html = html.replace(/&gt;/g, '>');
		html = html.replace(/\<\/b\>/gi, ' </b>');
		html = html.replace(/\<\/i\>/gi, ' </i>');
		html = html.replace(/\<\/a\>/gi, ' </a>');
		html = html.replace(/\<\/p\>/gi, ' </p>');
		return html;
	};

	handleStatus = (value) => {
		this.setState({ status: value });
	};

	handleDisplayInSchedule = (event) => {
		event.preventDefault();
		this.setState({ createEvent: true });
	};

	closeModal = () => {
		this.setState({ createEvent: false, tempPublishDate: this.state.published ?? this.inititalPublished });
	};

	handleLessons = (checked, lessonObj) => {
		this.setState((prevState) => ({
			selectedLesson: checked
				? [...prevState.selectedLesson, lessonObj]
				: prevState.selectedLesson.filter((x) => x.id != lessonObj.id),
			toggleLesson: checked
				? { ...prevState.toggleLesson, [lessonObj.id]: true }
				: { ...prevState.toggleLesson, [lessonObj.id]: false },
		}));
	};

	filterLesson = (data) => {
		const sortByStartTime = (a, b) => Moment(a.startTime).diff(b.startTime);

		if (this.state.selectedLesson.length > 0) {
			let filter = data.filter((item) => {
				return !this.state.selectedLesson.find((f) => {
					return f.id === item.id;
				});
			});

			return filter.concat(this.state.selectedLesson).sort(sortByStartTime);
		}

		return data.sort(sortByStartTime);
	};

	fetchLesson = () => {
		if (!this.state.allowFetch) {
			return;
		}
		//dont fetch if both start and end date is same
		let start = this.state.dateStart;
		let end = this.state.dateEnd;
		const diff = end.diff(start, 'minutes');
		if (diff === 0) {
			return;
		}

		this.setState({ isFetchingLesson: true });

		if (this.state.dateStart && this.state.dateEnd) {
			this.props.getScheduleForUser(
				this.props.user.id,
				Moment(this.state.dateStart).utc().set({ second: 0, millisecond: 0 }).toISOString(),
				Moment(this.state.dateEnd).utc().set({ second: 0, millisecond: 0 }).toISOString(),
			).then(() => {
				this.setState({
					lesson: this.filterLesson(this.props.usersSchedule[this.props.user.id]),
					isFetchingLesson: false,
					allowFetch: false,
				});
			}).catch((e) => {
				this.setState({
					isFetchingLesson: true,
				});
			});
		} else {
			let startTime = Moment().utc().set({ hour: 0, minute: 0, second: 0 }).toISOString();
			let endTime = Moment().utc().set({ hour: 23, minute: 59, second: 59 }).toISOString();

			this.props.getScheduleForUser(this.props.user.id, startTime, endTime)
				.then(() => {
					this.setState({
						lesson: this.filterLesson(this.props.usersSchedule[this.props.user.id]),
						isFetchingLesson: true,
						allowFetch: false,
					});
				}).catch((e) => {
					this.setState({
						isFetchingLesson: true,
					});
				});
		}
	};

	handleDisplayDate = (date) => {
		this.setState({
			tempPublishDate: Moment(date),
		});
	};

	onSubmitDisplayDate = () => {
		this.setState({
			published: this.state.tempPublishDate,
			createEvent: false,
		});
	};

	submit = (values) => {
		const { initialValues } = this.props;
		const { dateStart, dateEnd, attachedFiles, sectionId, published } = this.state;

		if (sectionId == 0 || sectionId == "") {
			return new Promise((resolve) => resolve(1));
		}

		return new Promise((resolve) => {
			let assessmentBlocks = [];
			let deletedBlocks = [];

			const isEditing = this.props.editView;

			if (values.groupEnabled == null || this.props.cloneView) {
				values.groupEnabled = true;
			}
			if (this.state.selectedLesson.length > 0) {
				let relation = [];
				this.state.selectedLesson.map((items) => {
					let obj = {
						CalendarEventId: items.id,
						ReferenceType: 'ASSIGNMENT',
						ReferenceId: 0,
					};
					relation.push(obj);
				});
				values.relationships = relation;
			} else {
				values.relationships = [];
			}

			values.startDate = dateStart;
			values.dueDate = dateEnd;
			values.sectionId = parseInt(sectionId);

			if (values.status === 'ASSIGNMENT_DRAFT') {
				values.published = '0001-01-01T00:00:00';
				values.status = this.state.status;
			} else {
				values.published = published;
			}
			values.attachedFiles = this.state.attachedFiles;

			if (values.status == null) {
				values.status == 'ASSIGNMENT_OPEN';
			}

			if (values.assessmentBlocks != null) {
				assessmentBlocks = [...values.assessmentBlocks];
			}

			if (values.assessmentType == null) {
				values.assessmentType = 'TEACHER';
			}

			if (this.state.newDocuments.length > 0) {
				values.attachedFiles = [...values.attachedFiles, ...this.state.newDocuments];
			}

			values.plans = null;

			if (typeof values.status === 'undefined') {
				values.status = this.state.status;
			}

			if (isEditing) {
				values.status = this.state.status;
			}

			if (initialValues != null && initialValues.assessmentBlocks != null) {
				deletedBlocks = [...initialValues.assessmentBlocks].filter((block) =>
					!assessmentBlocks.find(_bl => _bl.id == block.id) && !values.assessmentBlocks.find(_bl => _bl.id == block.id)
				);
			}

			delete values.assessmentBlocks;

			let that = this;
			if (isEditing && this.props.cloneView == false) {
				values.workspace = initialValues.workspace;
				this.props.updateAssignment(values).then(() => {
					let promises = [];

					if (deletedBlocks.length > 0) {
						deletedBlocks.forEach(block => {
							let deletePromise = new Promise(resolve => {
								this.props.deleteBlock(block.id).then(() => {
									resolve(1);
								})
							});

							promises.push(deletePromise);
						})
					}

					assessmentBlocks.forEach((block) => {
						promises.push(new Promise(async (resolve) => {
							const initialBlock = this.props.initialValues.assessmentBlocks.find((b) => b.id == block.id);

							if (initialBlock != null) {
								await this.props.updateAssessmentBlock(block, initialBlock);
							} else {
								await this.props.createAssessmentBlock(block, initialValues.id, 'ASSIGNMENT');
							}

							resolve(1);
						}));
					});

					Promise.all(promises).then(() => {
						that.props.addError(that.props.translate("changes-saved"), "info");
						that.props.onAbort(true, true, true);
						resolve(true);
					});
				});
			} else {
				if (this.props.cloneView) {
					values.documentTemplates = null;
					values.assignmentDocuments = null;
				}

				this.props.createAssignment(values).then(async () => {
					if (this.props.onSubmit) {
						let starttime = Moment(values.dueDate).utc().format();
						this.props.onSubmit(starttime);
					}
					if (this.state.planid != 0) {
						// Add this assignment as a block on the selected plan
						await new Promise(async resolve => {
							await this.props.getBlocksByReference(this.state.planid, 'PLAN');

							let blocks = [];

							if (this.props.planBlocks != null && this.props.planBlocks.length > 0) {
								blocks = [...this.props.planBlocks];

								blocks.forEach(block => {
									if (block.type == 'Haldor.Blocks.Assignment') {
										if (block.resources != null) {
											let resources = [];
											block.resources.forEach(resource => {
												resources.push({
													id: resource.id,
													'@odata.type': resource['@odata.type'],
												});
											});

											block.resources = resources;
										}
									}

									block['_Resources'] = null;

									return block;
								})
							}

							blocks.push({
								type: 'Haldor.Blocks.Assignment',
								title: '',
								value: '',
								sortorder: blocks.length,
								id: 0,
								resources: [
									{
										'@odata.type': 'haldor.assignment.block.assignment',
										id: this.props.newAssignment.id,
									}
								]
							});

							await this.props.updateBlocksOnReference(blocks, this.state.planid, 'PLAN');
							resolve(1);
						})
					}

					let promises = [];
					assessmentBlocks.forEach((block) => {
						if (this.props.cloneView) {
							block.resources.map((resource) => {
								resource.originId = resource.id;
								resource.id = 0;
								return resource;
							});
						}

						promises.push(new Promise((resolve) => {
							this.props.createAssessmentBlock(block, this.props.newAssignment.id, 'ASSIGNMENT')
								.then(() => {
									resolve(1);
								});
						}));
					});

					Promise.all(promises).then(() => {
						resolve(true);
						if (this.props.dontRedirect) {
							that.props.onAbort(true);
							return;
						}

						if (this.props.onCreated != null) {
							this.props.onCreated(this.props.newAssignment);
						} else {
							if (this.props.newAssignment != null && !this.props.dontRedirect) {
								var id = this.props.newAssignment.id;
								if (this.props.cloneView) {
									window.location = `${getRootUrl()}assignment/${id}`;
								} else {
									this.props.history.push(`${getRootUrl()}assignment/${id}`);
								}
							}
						}
					});

				});
			}
		});
	};

	onGroupChange = (groupId, group) => {
		const { translate } = this.props;

		if (group != null) {
			this.props.toggleSelectedSection(group, true);
		}

		if (groupId != 0) {
			this.setState({ sectionId: groupId }, () => {
				this.props.getSection(groupId).then(() => {
					if (this.props.section.students != null && this.props.section.students.length == 0) {
						swal.fire({
							title: this.props.translate('The group contains no students'),
							showCloseButton: true,
							confirmButtonText: this.props.translate('Ok'),
							html: `
							${translate('The group you selected does not contain any students. You can`t create assignments for a group without students. The group may lack students due to the fact that:')}
							<br />
							<ul>
								<li>${translate('No students have been added to the group. Make sure your team/Microsoft 365 group includes students.')}</li>
								<li>${translate('Students have not been activated on the team. To do this, use Microsoft Teams.')}</li>
								<li>${translate('The group could not be loaded correctly. Close the form and reload the page, then try again.')}</li>
							</ul>
							<br />
							`,
						});

						this.setState({ sectionId: 0 });
						return false;
					}

					this.props.getPlansBySectionId(groupId);

					if (this?.props?.displayTitle) {
						this.props.displayTitle(group.title);
					}
				});
			});
		} else {
			this.setState({ sectionId: 0 });
		}
	};

	onPlanChange = (event, form) => {
		if (event.target.value != '0') {
			this.setState({ planid: event.target.value, loadingPlan: true });
			this.props.getPlanDetails(event.target.value).then(async () => {
				await this.props.getBlocksByReference(event.target.value, 'PLAN');

				if (this.props.planBlocks != null) {
					const assessmentBlocks = this.props.planBlocks.filter((block) =>
						block.type == 'Haldor.Blocks.AssessmentBlock'
					).map((block) => ({
						...block,
						id: 0,
						referenceId: 0,
						referenceType: '',
					}));

					let values = form.getFieldState('assessmentBlocks')?.value;
					if (values == null) {
						values = [...assessmentBlocks];
					} else {
						values = [...values, ...assessmentBlocks];
					}

					form.change('assessmentBlocks', values);
				}

				this.setState({ loadingPlan: false });
			});
		} else {
			this.props.setActivePlan(null);
			this.setState({ loadingPlan: true, planid: 0 }, () => {
				this.setState({ loadingPlan: false });
			});
		}
	};

	onDateChange = (dates) => {
		this.setState({ dateStart: Moment(dates.start), dateEnd: Moment(dates.end), allowFetch: true });
	};

	selectDocument = (files) => {
		this.setState({ newDocuments: files.filter(file => file.mimeType !== 'unknown/mime-type') });
	};

	removeInstruction = (instruction, remove) => {
		let attachedFiles = this.state.attachedFiles;
		var removeFile = attachedFiles.find((file) => file.value == instruction);

		if (removeFile != null) {
			if (this.props.cloneView) {
				attachedFiles = attachedFiles.filter((t) => t.value != instruction);
			} else {
				if (removeFile.markedForDeletion == true && remove == false) {
					var removeFileIndex = attachedFiles.findIndex((file) => file.value == instruction);
					attachedFiles.splice(removeFileIndex, 1);
				}
			}
		} else {
			if (remove) {
				let assignmentDocuments = this.props.initialValues ?
					this.props.initialValues.assignmentDocuments != null ?
						this.props.initialValues.assignmentDocuments.split(";")
						: []
					: [];

				assignmentDocuments.forEach(document => {
					if (document == instruction && document != "" && document != null) {
						let removeFile = {
							markedForDeletion: remove,
							fileType: 1,
							value: document,
							key: '',
						};

						attachedFiles.push(removeFile);
					}
				});
			} else {
				let file = {
					fileType: 1,
					value: instruction,
					key: instruction.substring(instruction.lastIndexOf('/') + 1),
				};

				attachedFiles.push(file);
			}
		}

		this.setState({ attachedFiles });
	};

	removeTemplate = (template, remove) => {
		let attachedFiles = this.state.attachedFiles;
		const removeFile = attachedFiles.find((file) => file.value == template);
		if (removeFile != null) {
			if (this.props.cloneView) {
				attachedFiles = attachedFiles.filter((t) => t.value != template);
			} else {
				if (removeFile.markedForDeletion == true && remove == false) {
					var removeFileIndex = attachedFiles.findIndex((file) => file.value == template);
					attachedFiles.splice(removeFileIndex, 1);
				}
			}
		} else {
			if (remove) {
				let documentTemplates = this.props.initialValues
					? this.props.initialValues.documentTemplates != null
						? this.props.initialValues.documentTemplates.split(';')
						: []
					: [];

				documentTemplates.forEach((document) => {
					if (document == template && document != '' && document != null) {
						let removeFile = {
							markedForDeletion: remove,
							fileType: 2,
							value: document,
							key: '',
						};
						attachedFiles.push(removeFile);
					}
				});
			} else {
				let file = {
					fileType: 2,
					value: template,
					key: template.substring(template.lastIndexOf('/') + 1),
				};
				attachedFiles.push(file);
			}
		}

		this.setState({ attachedFiles });
	};

	renderInput = ({
		input,
		label,
		type,
		placeholder,
		meta: { touched, error, warning }
	}) => {
		return (
			<div>
				<input
					placeholder={placeholder || label}
					type={type}
					style={
						touched && error
							? {
								border: '1px solid red',
							}
							: {}
					}
					{...input}
				/>

				{touched &&
					((error && <span style={{ marginTop: '1rem', color: 'red' }}>{error}</span>) ||
						(warning && <span style={{ marginTop: '1rem', color: 'red' }}>{warning}</span>))}
			</div>
		);
	};

	getDateFormat = () => {
		let format = Moment.localeData(getActiveLanguage()).longDateFormat("L");
		let newFormat = format.replace("YYYY", "yyyy");
		let days = newFormat.replace("DD", "dd");
		return days;
	};

	normalizeLocale = (locale) => {
		const [language, region] = locale.split('-');

		if (language.toUpperCase() == "UK") {
			return `uk`;
		}
		return `${language}-${region.toUpperCase()}`;
	};

	/* Render form */
	render() {
		const { sectionId, attachedFiles } = this.state;
		const isEditing = this.props.editView;
		let groupId = null;
		if (this.state.loading) {
			return <Spinner center />;
		}
		let invalidDocuments = [...this.state.newDocuments].filter((file) => {
			if (file.fileType != null) {
				return file.fileType == 0;
			}

			return false;
		});

		let documentsPassing = true;
		if (invalidDocuments.length > 0) {
			documentsPassing = false;
		}

		if (this.state.sectionId != '0') {
			groupId = this.state.sectionId;
		}

		return (
			<div className='form-container form-create_task'>
				<InvalidGroupNotice />

				<Form
					onSubmit={this.submit}
					initialValues={this.props.initialValues}
					initialValuesEqual={() => true}
					mutators={{
						setStatusDraft: (args, state, utils) => {
							utils.changeValue(state, 'status', () => 'ASSIGNMENT_DRAFT');
						},
						setAutoAssignStudents: (args, state, utils) => {
							utils.changeValue(state, 'autoAssignStudents', () => args[0]);
						},
					}}
					render={({ handleSubmit, submitting, valid, form, initialValues }) => {
						this.formRef = form;

						let statusValue = initialValues?.status;
						if (form.getFieldState('status') != null) {
							statusValue = form.getFieldState('status').value;
						}
						let assessmentBlocks = form.getFieldState('assessmentBlocks')?.value;
						let subjects = [];
						if (assessmentBlocks?.length > 0) {
							assessmentBlocks?.forEach((content) => {
								content?.resources?.forEach(resources => {
									if (resources?.value != null) {
										if (subjects.indexOf(resources.value?.subjectId) == -1) {
											subjects.push(parseInt(resources.value?.subjectId));
										}
									}
								})
							});
						}

						console.log("edit form")

						/**
						 * Enable the auto assign option if all groups have all students selected
						 */
						let enableAutoAssign = this.state.enableAutoAssign;

						return (
							<form onSubmit={handleSubmit} className='form form-component'>
								{submitting ? (
									<div className='is_sending'>
										<p>
											<span className='loading-spinner' />
										</p>
									</div>
								) : null}

								{!isEditing ? (
									<div className='form-row'>
										<GroupSelector
											disabled={
												this.props.groupid && this.props.cloneView == false
													? true
													: false
											}
											onChange={this.onGroupChange}
											required
											sectionId={this.state.sectionId}
										/>
									</div>
								) : null}

								<div className='form-row select'>
									<label>{this.props.translate('Status')}</label>
									<select
										onChange={(e) => this.handleStatus(e.target.value)}
										value={this.state.status}
									>
										{this.props.assignmentStatus
											.filter((x) => x.id !== 'ASSIGNMENT_DRAFT')
											.map((item) => {
												return (
													<option key={item.id} value={item.id}>
														{item.id === 'ASSIGNMENT_CLOSED'
															? this.props.translate('Hidden')
															: item.value}
													</option>
												);
											})}
									</select>
								</div>

								<div className='form-row select'>
									<label>{this.props.translate('type')}*</label>

									<Field
										component='select'
										name='assignmentType'
										validate={this.required}
									>
										<option value=''>
											{this.props.translate('select-an-alternative')}
										</option>

										{this.props.assignmentTypes.map((item) => {
											return (
												<option key={item.id} value={item.id}>
													{item.value}
												</option>
											);
										})}
									</Field>
								</div>
								<div className='form-row input'>
									<label>{this.props.translate('title')}*</label>

									<Field
										component={this.renderInput}
										type='text'
										name='title'
										placeholder={this.props.translate('title')}
										validate={this.required}
									/>
								</div>

								<div className='form-row input'>
									<label>{this.props.translate('description')}</label>

									<Field
										component={Editor}
										name='description'
										placeholder={this.props.translate('describe-assignment')}
										translate={this.props.translate}
									/>
								</div>

								{!isEditing && !this.props.disablePlanSelector ? (
									<div className='form-row'>
										<label>{this.props.translate('planning')}</label>

										{this.props.sectionPlans !== null ? (
											<Select
												dataType='assignment-plan'
												placeholder={this.props.translate('pick-a-plan')}
												placeholderValue={null}
												disabled={
													this.props.planid && this.props.cloneView == false
														? true
														: false
												}
												selectedValue={this.state.planid}
												options={this.props.sectionPlans}
												onChange={e => this.onPlanChange(e, form)}
											/>
										) : (
											<Select
												dataType='assignment-plan'
												placeholder={this.props.translate('pick-a-plan')}
												placeholderValue=''
												disabled={this.props.planid ? true : false}
												selectedValue={this.state.planid}
												options={[]}
												onChange={e => this.onPlanChange(e, form)}
											/>
										)}
									</div>
								) : null}

								<div className='form-row'>
									<DatePickerFromTo
										timePicker
										type='assignment'
										onChange={this.onDateChange}
										values={{
											startDate: this.state.dateStart,
											dueDate: this.state.dateEnd,
										}}
										cloneView={this.props.cloneView}
									/>
								</div>

								{this.props.services.advancedSchedule ?
									<div className='form-row select'>
										<div className='lesson-picker'>
											<label>{this.props.translate('select lesson')}</label>
											<CustomSelector
												label={
													this.props.lesson ? (
														<div className='selectorLabel'>
															<span className='selectorItems'>
																{this.props.lesson?.startTime
																	? Moment(
																		this.props.lesson?.startTime
																	).format('dddd Do MMMM')
																	: this.props.time.format(
																		'dddd Do MMMM'
																	)}{' '}
																{this.props.lesson?.startTime
																	? Moment.utc(
																		this.props.lesson?.startTime
																	)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.format('LT')
																	: this.props.time.format('LT')}
																-{' '}
																{this.props.lesson?.startTime
																	? Moment.utc(this.props.lesson.startTime)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.add(
																			this.props.lesson.length,
																			'minutes'
																		)
																		.format('LT')
																	: Moment.utc(this.props?.time)
																		.set({
																			second: 0,
																			millisecond: 0,
																		})
																		.local()
																		.add(this.props?.end, 'minutes')
																		.format('LT')}
															</span>

															<span className='selectorItems'>
																{this.props?.lesson.title}
															</span>
														</div>
													) : this.state.selectedLesson.length > 1 ? (
														<span>
															{this.state.selectedLesson.length}{' '}
															{this.props.translate('lessons selected')}
														</span>
													) : this.state.selectedLesson.length === 1 ? (
														<div className='selectorLabel'>
															<span className='selectorItems'>
																{ }
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).local().format('dddd Do MMMM')}{' '}
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).set({
																	second: 0,
																	millisecond: 0,
																}).local().format('LT')}{' '}
																-{' '}
																{Moment.utc(
																	this.state.selectedLesson[0].startTime
																).local()
																	.add(
																		this.state.selectedLesson[0].length,
																		'minutes'
																	)
																	.format('LT')}
															</span>

															<span className='selectorItems'>
																{this.state.selectedLesson[0].title}
															</span>
														</div>
													) : (
														<span>
															{this.props.translate(
																'select lesson'
															)}
														</span>
													)
												}
												lesson={this.props.lesson}
												fetchLesson={this.fetchLesson}
											>
												{!this.state.isFetchingLesson ? (
													this.state.lesson.length === 0 ? (
														<div className='noLesson'>
															<span>
																{this.props.translate('There are no lessons within the assignment time period')}
															</span>
														</div>
													) : (
														this.state.lesson.map((lesson) => {
															return (
																<div key={lesson.id} tabIndex={0}>
																	<Checkbox
																		value={
																			this.state.toggleLesson[
																			lesson.id
																			]
																		}
																		onChange={(checked) =>
																			this.handleLessons(
																				checked,
																				lesson
																			)
																		}
																		label={<div>
																			{Moment(lesson.startTime).format('dddd Do MMMM')}{' '}

																			{Moment.utc(lesson.startTime).local().set({
																				second: 0,
																				millisecond: 0,
																			}).format('LT')}{' '}
																			-{' '}
																			{Moment.utc(lesson.startTime).local().set({
																				second: 0,
																				millisecond: 0,
																			}).add(lesson.length, 'minutes').format('LT')}{' '}
																			{lesson.title}
																		</div>}
																	/>
																</div>
															);
														})
													)
												) : (
													<Spinner center />
												)}
											</CustomSelector>
										</div>
									</div>
									: null}

								<div style={{ clear: 'both' }} />

								{this.props.services.advancedSchedule ?
									<div className='form-row' style={{ marginBottom: '1rem' }}>
										<label>{this.props.translate('publication date')}</label>
										<Button
											type='dropDown'
											onClick={(e) => this.handleDisplayInSchedule(e)}
										>
											{this.state.published.format('dddd Do MMMM')}{' '}
											{this.state.published.format('LT')}
										</Button>
									</div>
									: null}

								{(this.props.editView || this.props.cloneView) &&
									initialValues.assignmentDocuments !== null &&
									initialValues.assignmentDocuments.length > 0 ? (
									<div
										className='form-row'
										style={{ marginBottom: '1em', width: '100%' }}
									>
										<label>{this.props.translate('instructions')}</label>

										<AttachedDocuments
											documents={initialValues.assignmentDocuments}
											removeInstruction={this.removeInstruction}
										/>
									</div>
								) : null}

								{this.props.cloneView &&
									initialValues.documentTemplates !== null &&
									initialValues.documentTemplates.length > 0 ? (
									<div
										className='form-row'
										style={{ marginBottom: '1em', width: '100%' }}
									>
										<label>{this.props.translate('templates')}</label>

										<AttachedDocuments
											documents={initialValues.documentTemplates}
											removeInstruction={this.removeTemplate}
										/>
									</div>
								) : null}

								<div className='form-divider' />

								<Expandable title={this.props.translate('Assessments')}>
									{!this.state.loadingPlan ?
										<Field
											component={BlockAssessments}
											name="assessmentBlocks"
											multipleAssessmentOptions={!this.props.editView ?
												false
												: !this.props.initialValues?.groupEnabled
											}
											assessmentFeedbackParts={this.props.cloneView ? null : this.props.assessments.reduce((prev, current, index) => {
												return [
													...prev,
													...this.props.assessments[index]?.assessmentFeedbacks?.map((feedback) =>
														feedback.assessmentBlockPartId
													)
												]
											}, [])}
											disableRows={this.props.cloneView ? null : this.props.assessments.reduce((prev, current, index) => {
												return [
													...prev,
													...this.props.assessments[index]?.assessmentDetails?.map((detail) =>
														detail.assessmentBlockPartRowId
													)
												]
											}, [])}
										/>
										: null}
								</Expandable>

								<Upload
									lockedStatus={
										this.state.status === 'ASSIGNMENT_LOCKED' ? true : false
									}
									onUpdate={this.selectDocument}
									attachedFiles={attachedFiles}
									form='assignment'
								/>

								{this.props.services.additionalAdjustments ? (
									<Expandable title={this.props.translate("additional-adjustments")}>
										<TeacherAdjustments
											disableCommenting={true}
											groupId={groupId}
											subjects={subjects}
										/>
									</Expandable>
								) : null}

								<Expandable
									open={true}
									ignorePropsUpdate
									title={this.props.translate('assign-students') + '*'}
								>
									<Field
										component={AssignmentTaskSelect}
										name='tasks'
										section={this.props.section}
										copyMode={this.props.cloneView}
										groupEnabled={this.props.cloneView ?
											true : this.props.initialValues?.groupEnabled
										}
										validate={this.requireAssignmentTasks}
									/>

									<div style={{ marginTop: '1rem' }}>
										<Field name="autoAssignStudents" type="checkbox">
											{({ input }) => {
												return (
													<Checkbox
														{...input}
														onChange={(event) => {
															input.onChange(event);
														}}
														value={input.checked}
														disabled={!enableAutoAssign}
														checked={input.checked}
														label={this.props.translate(
															'Assign the planning to future students who will be added to the selected groups'
														)}
													/>
												)
											}
											}
										</Field>
									</div>

								</Expandable>

								<div className='form-divider' />

								<div className='form-row spacing submit'>
									{isEditing ? (
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.submit();
											}}
											type='save'
											disabled={submitting || !valid || !documentsPassing}
										>
											{this.props.translate('save')}
										</Button>
									) : (
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.submit();
											}}
											type='save'
											disabled={
												submitting ||
												!valid ||
												sectionId == '0' ||
												!documentsPassing
											}
										>
											{this.props.translate('publish')}
										</Button>
									)}

									{isEditing && initialValues.published != '0001-01-01T00:00:00' ? null : (
										<Button
											onClick={(e) => {
												e.preventDefault();
												form.mutators.setStatusDraft();
												form.submit();
											}}
											type='secondary'
											disabled={
												submitting ||
												!valid ||
												sectionId == '0' ||
												!documentsPassing
											}
										>
											{this.props.translate('save-draft')}
										</Button>
									)}

									<div className='align-right'>
										<Button
											type='secondary'
											onClick={(e) => {
												e.preventDefault();
												this.props.onAbort(false);
											}}
										>
											{this.props.translate('Cancel')}
										</Button>
									</div>
								</div>
							</form>
						);
					}}
				/>

				<Modal
					isOpen={this.state.createEvent}
					onClose={this.closeModal}
					title={this.props.translate('add-date')}
				>
					<div className='in_scheduleFrom'>
						<div className='timeStart_scheduleFrom'>
							<span>{this.props.translate('start')}*</span>

							<DatePicker
								selected={this.state.tempPublishDate.toDate()}
								startDate={this.state.tempPublishDate.toDate()}
								onChange={this.handleDisplayDate}
								dateFormat={this.getDateFormat()}
								popperPlacement='bottom-end'
								positionFixed={true}
								popperModifiers={{
									preventOverflow: {
										enabled: true,
									},
									positionFixed: false,
								}}
								selectsEnd
								showWeekNumbers
								fixedHeight
								previousMonthButtonLabel=''
								nextMonthButtonLabel=''
								locale={this.normalizeLocale(getActiveLanguage())}
							/>
						</div>
						<div className='time_scheduleFrom'>
							<span>{this.props.translate('start-time')}*</span>

							<TimePicker
								placeholder={this.props.translate('pick-start-time')}
								time={this.state.tempPublishDate}
								onChange={this.handleDisplayDate}
							/>
						</div>
						<div className='submitTime'>
							<Button onClick={this.onSubmitDisplayDate}>{this.props.translate('Save')}</Button>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	let initialValues = null;
	if (state.assignments.active_assignment != null) {
		initialValues = state.assignments.active_assignment;
	}

	if (state.Blocks.reference != null && initialValues != null) {
		let blocks = JSON.parse(JSON.stringify(state.Blocks.reference));
		initialValues.assessmentBlocks = blocks.filter((block) =>
			block.type == "Haldor.Blocks.AssessmentBlock"
		);
	}

	//handle current planningAssessments
	if (ownProps?.planningAssessments?.length > 0) {
		if (initialValues === null) {
			initialValues = {};
		}
		let filteredAssessmentsBlocks = ownProps?.planningAssessments.filter(t => t.type === 'Haldor.Blocks.AssessmentBlock');
		if (filteredAssessmentsBlocks.length > 0) {
			initialValues.assessmentBlocks = filteredAssessmentsBlocks;
		}
	}

	//handle previous planningAssessments
	if (state.planning.active_plan?.blocks?.length > 0 && ownProps.existingPlanningAssessments) {
		if (initialValues === null) {
			initialValues = {};
		}

		initialValues.assessmentBlocks = state?.planning?.active_plan?.blocks?.filter(t => t.type === 'Haldor.Blocks.AssessmentBlock');
	}

	if (ownProps.time != null) {
		if (initialValues === null) {
			initialValues = {};
		}

		initialValues.startDate = Moment(ownProps.time);
	}

	if (ownProps.length != null && initialValues.startDate != null) {
		if (initialValues === null) {
			initialValues = {};
		}

		initialValues.dueDate = Moment(initialValues.startDate).add(ownProps.length, 'minutes');
	}

	if (ownProps.lesson != null) {
		if (initialValues == null) {
			initialValues = {};
		}

		const lesson = JSON.parse(JSON.stringify(ownProps.lesson));

		if (ownProps.lesson.startTime != null) {
			initialValues.startDate = Moment.utc(lesson.startTime).local();
			initialValues.dueDate = Moment(initialValues.startDate).add(ownProps.lesson.length, 'minutes');
		}
	}

	return {
		initialValues: initialValues,
		assessments: state.assessments.assessments,
		user: state.user.currentUser,
		sections: state.sections.educationGroups,
		section: state.sections.activeSection,
		assignmentTypes: state.status.status.assignmentTypes,
		assignmentStatus: state.status.status.assignmentStatus,
		translate: translate(state.Languages.translations),
		newAssignment: state.assignments.newAssignment,
		services: state.Services.availableServices,
		connectedLessons: state.schedule.selectedLessons,
		usersSchedule: state.schedule.usersSchedule,
		sectionPlans: state.planning.sectionPlans,
		plan: state.planning.active_plan,
		planBlocks: state.Blocks.reference,
	};
}

export default withRouter(connect(mapStateToProps, {
	createAssignment,
	updateAssignment,
	addError,
	getTaskDetails,
	setActiveAssignment,
	deleteBlock,

	getSection,
	toggleSelectedSection,
	clearSelectedSections,
	setActiveSection,
	getScheduleForUser,
	clearSearchedSchedules,

	getPlanDetails,
	getPlansBySectionId,
	setActivePlan,
	clearSectionPlansFromStore,
	getScheduleItems,
	getBlocksByReference,
	updateBlocksOnReference,
	createAssessmentBlock,
	updateAssessmentBlock,
})(AssignmentForm));
